import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import store, { actions, getters } from 'redux/store';
import { useSelector } from 'react-redux';
import Layout from 'components/Order/Layout';
import Coupon from 'components/UI/Card/CouponCenter';
import ModalUsage from './ModalUsage';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { COUPON_STATUS } from 'utils/constants/couponStatus.js';
import IconEmpty from 'images/girl.svg';
import Skeleton from 'react-loading-skeleton';
import CcAuth from 'components/CcAuth';

const UsageBtn = styled.div`
  width: 114px;
  height: 38px;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;

  @media (max-width: ${md}) {
    display: flex;
    order: 3;
    margin-left: auto;
    width: 88px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: #eafeff;
      border: 1px solid #5fd2da;
      border-radius: 30px;
    `}
`;

const mixinRefundList = css`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  @media (max-width: ${md}) {
    gap: 24px 0;
  }
`;

const mixinRefundItem = css`
  flex-basis: calc((100% - 12px) / 2);
  border-radius: 8px;
  min-height: 127px;

  @media (max-width: ${md}) {
    flex-basis: 100%;
  }
`;

const Container = styled.div`
  .skeleton-refund-list {
    ${mixinRefundList};

    .skeleton-refund {
      ${mixinRefundItem};
    }
  }
  @media (max-width: ${md}) {
    background-color: #fff;
    padding: 8px 16px;
    height: calc(100vh - 54px);
  }

  ${UsageBtn} {
    display: flex;

    @media (max-width: ${md}) {
      display: none;
    }
  }
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
`;

const TitleBarBtnGroup = styled.div`
  display: flex;
  gap: 0 8px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;

  @media (max-width: ${md}) {
    gap: 8px 0;
  }
`;

const StatusBtnGroup = styled.div`
  display: flex;
  gap: 0 10px;
  padding: 4px 0;

  @media (max-width: ${md}) {
    justify-content: center;
  }
`;

const StatusBtn = styled.div`
  border-radius: 30px;
  border: 1px solid #5fd2da;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #868686;
  width: 94px;
  height: 37px;
  cursor: pointer;

  @media (max-width: ${md}) {
    font-size: 14px;
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: #eafeff;
      font-weight: 700;
      color: #3b3516;
    `}
`;

const CouponListWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100% - 85px);
  padding: 2px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CouponList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;

  > div {
    flex: 0 0 calc((100% - 12px) / 2);

    @media (max-width: ${md}) {
      flex: 0 0 100%;
    }
  }
`;

const Empty = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 43px;
`;

const EmptyTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #3b3516;
  margin-top: 24px;

  @media (max-width: ${md}) {
    font-size: 20px;
    margin-top: 15px;
  }
`;

const EmptySubtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  margin-top: 24px;

  @media (max-width: ${md}) {
    margin-top: 8px;
  }
`;

const GoBtn = styled(Link)`
  width: 230px;
  height: 60px;
  background-color: #5fd2da;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 24px;
  border-radius: 30px;
  border: 1px solid #5fd2da;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${md}) {
    width: 187px;
    height: 40px;
    font-size: 16px;
  }
`;

const btnList = [
  {
    key: COUPON_STATUS.CAN_USE,
    label: '可使用',
    emptyTitle: '沒有可用的折價券',
  },
  {
    key: COUPON_STATUS.CAN_NOT_USE,
    label: '尚未啟用',
    emptyTitle: '沒有尚未啟用的折價券',
  },
  {
    key: COUPON_STATUS.NOT_ACTIVE,
    label: '不可使用',
    emptyTitle: '沒有不可使用的折價券',
  },
];

const MyCoupon = (props) => {
  const pageTitle = '我的折價券';
  const [showModalUsage, setShowModalUsage] = useState(false);
  const [status, setStatus] = useState(COUPON_STATUS.CAN_USE);
  const [couponListCanUse, setCouponListCanUse] = useState([]);
  const [couponListCanNotUse, setCouponListCanNotUse] = useState([]);
  const [couponListNotActive, setCouponListNotActive] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [usageList, setUsageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPage, setShowPage] = useState(true);
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);
  const api = useAPI();

  const closeModalUsage = () => {
    setShowModalUsage(false);
  };

  useEffect(() => {
    if (!showPage) {
      setShowPage(true);
    }
  }, [showPage]);

  const getMyCouponList = (status) => {
    const couponLists = {
      [COUPON_STATUS.CAN_USE]: couponListCanUse,
      [COUPON_STATUS.CAN_NOT_USE]: couponListCanNotUse,
      [COUPON_STATUS.NOT_ACTIVE]: couponListNotActive,
    };

    const couponListToSet = couponLists[status];

    if (couponListToSet && couponListToSet.length > 0) {
      setCouponList(couponListToSet);
      setShowPage(false);
      return;
    }
    setLoading(true);
    api
      .getMyCouponList(status)
      .then((res) => {
        setLoading(false);
        if (res) {
          setCouponList(res);

          switch (status) {
            case COUPON_STATUS.CAN_USE:
              setCouponListCanUse(res);
              break;
            case COUPON_STATUS.CAN_NOT_USE:
              setCouponListCanNotUse(res);
              break;
            default:
              setCouponListNotActive(res);
              break;
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const getUsage = () => {
    api
      .getUsageList()
      .then((res) => {
        console.log(res);
        if (res) {
          setUsageList(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!ccAuthIsReady) return;
    getMyCouponList(COUPON_STATUS.CAN_USE);
    getUsage();
  }, [ccAuthIsReady]);

  return (
    <>
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {ccAuthIsReady && (
        <Layout
          {...props}
          pageTitle={pageTitle}
          extraBtn={
            <UsageBtn onClick={() => setShowModalUsage(true)}>
              使用辦法
            </UsageBtn>
          }
        >
          <Container>
            <TitleBar>
              <Title>{pageTitle}</Title>
              <TitleBarBtnGroup>
                <UsageBtn onClick={() => setShowModalUsage(true)}>
                  使用辦法
                </UsageBtn>
              </TitleBarBtnGroup>
            </TitleBar>

            {showPage && (
              <PageContent>
                <StatusBtnGroup>
                  {btnList.map((btn) => {
                    return (
                      <StatusBtn
                        key={btn.key}
                        isActive={status === btn.key}
                        onClick={() => {
                          setStatus(btn.key);
                          getMyCouponList(btn.key);
                        }}
                      >
                        {btn.label}
                      </StatusBtn>
                    );
                  })}
                </StatusBtnGroup>

                {loading ? (
                  <Skeleton
                    containerClassName="skeleton-refund-list"
                    className="skeleton-refund"
                    count={10}
                    inline
                  />
                ) : couponList.length === 0 ? (
                  <Empty>
                    <img src={IconEmpty} />
                    <EmptyTitle>
                      {btnList.find((b) => b.key === status).emptyTitle}
                    </EmptyTitle>
                    <EmptySubtitle>前往折價券中心了解更多</EmptySubtitle>
                    <GoBtn to="/coupon">前往折價券中心</GoBtn>
                  </Empty>
                ) : (
                  <CouponListWrapper>
                    <CouponList>
                      {couponList?.map((coupon, idx) => {
                        return (
                          <Coupon
                            key={idx}
                            data={coupon}
                            status={status}
                            isFromMyCoupon={true}
                          ></Coupon>
                        );
                      })}
                    </CouponList>
                  </CouponListWrapper>
                )}
              </PageContent>
            )}
          </Container>
        </Layout>
      )}

      <ModalUsage
        usageList={usageList}
        isOpen={showModalUsage}
        onCancel={closeModalUsage}
      />
    </>
  );
};

export default MyCoupon;
